<template>
  <div class="row justify-content-md-center" :class="{'loading': !loaded}">
    <div class="col-md-3 text-left dark-box">
      <div class="row">
        <div class="row col-12 mb-5">
          <div  class="row col-12 mb-3">
            <div class="col-12 text-muted">ID</div>
            <div class="col-12">{{ modalData.data.id_log_follow_up }}</div>
          </div>
          <div  class="row col-12 mb-3">
            <div class="col-12 text-muted">Tipo</div>
            <div class="col-12">{{ modalData.data.type_name }}</div>
          </div>
          <div  class="row col-12 mb-3">
            <div class="col-12 text-muted">Status</div>
            <div class="col-12">{{ modalData.data.status_name }}</div>
          </div>
          <div v-if="modalData.data.status === 4" class="row col-12 mb-3">
            <div class="col-12 text-muted">Cód. Erro</div>
            <div class="col-12">{{ modalData.data.erro_code }}</div>
          </div>

          <div  class="row col-12 mb-3">
            <div class="col-12 text-muted">Enviar às</div>
            <div class="col-12">{{ this.formatDateTime(modalData.data.to_send_date) }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-9 modal-box">
      <div class="close-button mb-4">
        <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
          <span><i class="text-primary rc-Ativo-36"> </i></span>
        </div>
      </div>

      <div class="row">
        <div  v-if="modalData.data.status === 4" class="col-12 col-md-8">
          <div>
              Mensagem
          </div>
            <pre>{{ modalData.info }}</pre>
        </div>

        <div v-else class="col-12 col-md-12">
          <table class="table">
            <tbody >
            <tr>
              <td class="label">Status</td>
              <td class="status">{{ modalData.info.status}}</td>
            </tr>
            <tr>
              <td class="label">Destinatário(s)</td>
              <td class="recipients">{{ modalData.info.recipientscount }}</td>
            </tr>

            <tr class="pending">
              <td class="label">Aguardando Envio</td>
              <td class="value">
                <div v-for="(pending, index) in modalData.info.pending" v-bind:key="index">
                  {{ pending }}
                </div>
              </td>
            </tr>

            <tr class="delivered">
              <td class="label">Enviados</td>
              <td class="value">
                <div v-for="(delivered, index) in modalData.info.delivered" v-bind:key="index">
                  {{ delivered }}
                </div>
              </td>
            </tr>

            <tr class="opened">
              <td class="label">Abertos</td>
              <td class="value">
                <div v-for="(opened, index) in modalData.info.opened" v-bind:key="index">
                  {{ opened }}
                </div>
              </td>
            </tr>

            <tr class="failed">
              <td class="label">Não entregues</td>
              <td class="value">
                <div v-for="(failed, index) in modalData.info.failed" :title="'(' + failed.errorcode + ') '+ failed.error" v-bind:key="index">
                  {{ failed.address }} ({{ failed.category }})
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">

        </div>
        <div class="col-12 col-md-4">

        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">

        </div>
        <div class="col-12 col-md-4">

        </div>
        <div class="col-12 col-md-4">

        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-4">

        </div>
        <div class="col-12 col-md-4">
          <!-- <input data-index="2" v-model="filter.endDate" id="endDate" readonly class="md-form-control" type="text"> -->

        </div>
        <div class="col-12 col-md-4">

        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 col-md-8">

        </div>
        <div class="col-12 col-md-4">

        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8">

        </div>
      </div>

      <div class="row mt-3">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MailLog',
  data () {
    return {
      loaded: true,
      btnSaveImage: false,
      mdlFooterDisable: false,
      imgSrc: '',
      cropImg: ''
    }
  },
  components: {
  },
  methods: {
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    onClose () {
      this.$emit('close')
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    modalData () {
      return this.$store.getters.getModalData || null
    }
  }
}
</script>

<style scoped>
  .loading {
    pointer-events: none;
    opacity: 0.4;
  }

  .justify-content-md-center {
    padding: 20px;
  }

  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .img-container:hover .img-profile {
    opacity: 0.3;
  }

  .img-container:hover .middle {
    opacity: 1;
  }

  .text {
    /* background-color: #4CAF50; */
    color: white;
    font-size: 16px;
    padding: 16px 32px;
    content: "\e64e";
  }

  .modal-box{
    padding: 75px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }
</style>
